import { Page } from '../page';

export function Accommodation() {
  return (
    <Page
      header='Accommodation'
      gallery={[
        { name: 'courtyard.jpg', caption: 'Courtyard - Ashley Park' },
        { name: 'view.jpg', caption: 'View from ceremony room - Ashley Park' }
      ]}
    >
      <div className='p-6 flex flex-col items-center gap-3 font-bold font-gel'>
        <span className='font-bold text-2xl'>On-site</span>
        <hr className='w-28 self' />
        <p>
          Accommodation is available at Ashley Park House but is limited. Please let us know if you would like to stay the night of our wedding and
          the night of our day two party by emailing us on{' '}
          <a className='text-blue' target='_blank' rel='noreferrer' href='mailto:wedding@bryan-podge-2024.com?subject=Wedding Accommodation'>
            wedding@bryan-podge-2024.com
          </a>{' '}
          or text Podge on{' '}
          <a className='text-blue' target='_blank' rel='noreferrer' href='tel:+353861660384'>
            0861660384
          </a>{' '}
          or Bryan on{' '}
          <a className='text-blue' target='_blank' rel='noreferrer' href='tel:+353851429464'>
            0851429464
          </a>{' '}
          with details of your reservation. Ashley Park House do not take wedding reservations directly.
          <br />
          <br />
          B&B rates are €195/night for two person sharing a twin/double room.
          <br />
          <br />
        </p>
        <span className='font-bold text-2xl'>Bus</span>
        <hr className='w-16 self' />
        <p>
          A bus is organised to bring people back to near by accommodation on the night of our wedding. The bus will run until 3:00am and will{' '}
          <span className='underline font-bold'>only service the listed accommodation below</span>.
          <br />
          <br />
          If you have booked any of the accommodation listed below can you let us know in advance if possible so we can let the bus driver know of
          rough numbers.
        </p>
        <span className='mt-6 font-bold text-2xl'>Alternative Accommodation (serviced by bus)</span>
        <hr className='w-80 self' />
        <span className='mt-2'>We recommend ringing accommodations as they would be aware of a large wedding at Ashley Park</span>
        <div className='p-5 h-auto flex flex-col justify-stretch content-evenly basis-1/2'>
          <div className='flex flex-col items-start text-left gap-3'>
            <div>
              <a className='text-blue' target='_blank' rel='noreferrer'>
                Hibernian Inn, Nenagh
              </a>
              <div className='text-sm'>9 min drive away - Contact 067 41366</div>
            </div>
            <div>
              <a className='text-blue' href='https://williamsferry.com/' target='_blank' rel='noreferrer'>
                Williamsferry House B&amp;B
              </a>

              <div className='text-sm'>12 min drive away</div>
            </div>
            <div>
              <a className='text-blue' href='https://willowbrook.ie/' target='_blank' rel='noreferrer'>
                Willowbrook Bed &amp; Breakfast, Nenagh
              </a>

              <div className='text-sm'>8 min drive away - Contact 067 31558</div>
            </div>
            <div>
              <a className='text-blue' href='http://maryvillebandb.com/' target='_blank' rel='noreferrer'>
                Maryville Bed &amp; Breakfast, nenagh
              </a>

              <div className='text-sm'>9 min drive away - Contact 067 32531</div>
            </div>
            <div>
              <a className='text-blue' href='https://www.airbnb.ie/rooms/50182212' target='_blank' rel='noreferrer'>
                Rapla South
              </a>

              <div className='text-sm'>8 min drive away</div>
            </div>
            <div>
              <a
                className='text-blue'
                href='https://www.booking.com/hotel/ie/abbey-court-lodges-trinity-leisure-spa.html'
                target='_blank'
                rel='noreferrer'
              >
                Abbey Court Hotel
              </a>

              <div className='text-sm'>11 min drive away; contact brian.oneill@abbeycourt.ie directly for one night stay</div>
            </div>
            <div>
              <a className='text-blue' href='http://www.abbeyview.ie/' target='_blank' rel='noreferrer'>
                Abbeyview Bed &amp; Breakfast
              </a>

              <div className='text-sm'>12 min drive away</div>
            </div>
            <div>
              <a className='text-blue' href='https://loughderghouse.ie/accommodation-domineer-nenagh/' target='_blank' rel='noreferrer'>
                Lough Derg House
              </a>

              <div className='text-sm'>11&nbsp;min drive away - Contact 087 7730023</div>
            </div>
            <div>
              <a
                className='text-blue'
                href='https://www.airbnb.ie/rooms/21286118?guests=1&adults=1&s=67&unique_share_id=17087d15-8dda-45f9-a2f2-2a094ec17060'
                target='_blank'
                rel='noreferrer'
              >
                Salmon Island View, Dromineer
              </a>

              <div className='text-sm'>15 min drive away - Contact 087 1461165</div>
            </div>
            <div>
              <a
                className='text-blue'
                href='https://www.airbnb.ie/rooms/18511075?guests=1&adults=1&s=67&unique_share_id=93d50ad9-a3f3-41e0-b5ec-9bdbc8ebe95b'
                target='_blank'
                rel='noreferrer'
              >
                Harbour View, Dromineer
              </a>

              <div className='text-sm'>15 min drive away - Contact Helen 087 2957910</div>
            </div>
            <div>
              <a className='text-blue' href='https://www.booking.com/Share-ALLLC3Q' target='_blank' rel='noreferrer'>
                Claire Street B&B
              </a>

              <div className='text-sm'>8 min drive away - Contact 067 43627</div>
            </div>
            <div>
              <a className='text-blue' href='https://andysnenagh.com/' target='_blank' rel='noreferrer'>
                Andy Flynn's, Nenagh
              </a>
              <div className='text-sm'>8 min drive away - Contact 067 32494</div>
            </div>
          </div>
        </div>
        <span className='mt-6 font-bold text-2xl'>Other Accommodation</span>
        <hr className='w-80 self' />
        <span className='mt-2'>
          All other accommodations <span className='underline'>will not</span> be serviced by our bus, unfortunately. Below is a list of local taxis,
          we recommend pre-booking a taxi if needed.
        </span>

        <div>
          <ul>
            <li className='m-2'>Senan Mulqueen - 087 671 8884</li>
            <li className='m-2'>Ger Kennedy - 087 246 8100</li>
            <li className='m-2'>Premier Cabs; Noel Bowler - 086 810 9247</li>
            <li className='m-2'>Tommy Ryan - 086 244 4657</li>
            <li className='m-2'>John Maher - 087 640 5086</li>
            <li className='m-2'>Roy Kennedy - 089 443 0171</li>
            <li className='m-2'>Ray Brady - 087 051 8500</li>
            <li className='m-2'>Clifford Cabs - 067 34506 or 087 122 4008</li>
            <li className='m-2'>Arra Cabs - 086 266 4060</li>
          </ul>
        </div>
      </div>
    </Page>
  );
}
