import { Page } from '../page';

export function DayTwo() {
  return (
    <Page header='Day Two' gallery={[{ name: 'pb2.jpg' }, { name: 'pb5.jpeg' }]}>
      <p className='text-lg p-6'>
        We will be spending a second day, bank holiday Monday 5<sup>th</sup> August, at Ashley Park House. There will be food and entertainment for
        all our guests. Anyone who would like to join us for day two are more than welcome.
        <br />
        <br />
        Our day two celebrations will start at around 3pm with some garden games, food will be served at 5pm in the pavilion, with some entertainment
        after.
      </p>
    </Page>
  );
}
