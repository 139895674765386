import { Field, FieldAttributes, useField } from 'formik';

export interface TextInputProps extends FieldAttributes<any> {
  label: string;
}
export function TextInput({ label, name, ...props }: TextInputProps) {
  const [, { error, touched }] = useField(name);
  return (
    <div className='flex flex-col gap-2'>
      <label htmlFor={name} className='text-left'>
        {label}:{' '}
      </label>
      <Field
        type='text'
        id={name}
        name={name}
        placeholder={label}
        {...props}
        className={'border border-gray-700 px-2 rounded-sm ' + (error && touched ? 'border-red-500' : '')}
      />
      <div className='h-6 text-red-500 text-left'>{error}</div>
    </div>
  );
}
