
export interface GuestBase {
  firstName: string;
  lastName: string;
  alternate?: GuestBase[];
}
export interface Guest extends GuestBase {
  email: string;
  attending: boolean;
  dietaryRequirements: string;
  starter: Starter;
  soupSorbet: SoupSorbet;
  mains: Mains;
  dessert: Dessert;
  songRequest: string;
}

export interface Invite {
  id: string;
  guest1: GuestBase;
  guest2: GuestBase;
}

export interface RSVP {
  id: string;
  guest1: Guest;
  guest2: Guest;
  plusOne?: boolean;
}

export enum Starter {
  ChickenAndMushroom = 'Vol-au-vent',
  Duck = 'Duck',
  None = 'None'
}

export enum SoupSorbet {
  Soup = 'Soup',
  Sorbet = 'Sorbet',
  None = 'None'
}

export enum Mains {
  Veg = 'Veg',
  Wellington = 'Wellington',
  Fish = 'Fish',
  None = 'None'
}

export enum Dessert {
  Crumble = 'Crumble',
  ChocolateCake = 'ChocolateCake',
  None = 'None'
}