import { useMutation } from '@tanstack/react-query';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Dessert, Invite, Mains, RSVP, SoupSorbet, Starter } from '../models/models';
import { Page } from '../page';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import Alert from '@mui/material/Alert';

import { v4 } from 'uuid';
import { RSVPForm } from './RSVPForm';

const guestValidationSchema = (yup: Yup.ObjectSchema<{}, Yup.AnyObject, {}, ''>) =>
  yup.shape({
    firstName: Yup.string().when('attending', { is: 'true', then: (yup) => yup.required('First name is required') }),
    lastName: Yup.string().when('attending', { is: 'true', then: (yup) => yup.required('Last name is required') }),
    email: Yup.string(),
    attending: Yup.string().required('RSVP is required'),
    dietaryRequirements: Yup.string(),
    starter: Yup.mixed<Starter>().when('attending', {
      is: 'true',
      then: (yup) => yup.required('Starter selection is required')
    }),
    soupSorbet: Yup.mixed<SoupSorbet>().when('attending', {
      is: 'true',
      then: (yup) => yup.required('Soup or sorbet selection is required')
    }),
    mains: Yup.mixed<Mains>().when('attending', {
      is: 'true',
      then: (yup) => yup.required('Mains selection is required')
    }),
    dessert: Yup.mixed<Dessert>().when('attending', {
      is: 'true',
      then: (yup) => yup.required('Dessert selection is required')
    }),
    songRequest: Yup.string()
  });

const validationSchema = Yup.object().shape({
  guest1: guestValidationSchema(Yup.object()),
  guest2: Yup.object().when('plusOne', { is: true, then: guestValidationSchema }),
  plusOne: Yup.boolean()
});

const initialValues = {
  id: v4(),
  guest1: {
    firstName: '',
    lastName: '',
    email: '',
    attending: '',
    dietaryRequirements: '',
    starter: '',
    soupSorbet: '',
    mains: '',
    dessert: '',
    songRequest: ''
  },
  guest2: {
    firstName: '',
    lastName: '',
    email: '',
    attending: '',
    dietaryRequirements: '',
    starter: '',
    soupSorbet: '',
    mains: '',
    dessert: '',
    songRequest: ''
  },
  plusOne: false
};

export function RSVPPage() {
  const formikRef = React.createRef<FormikProps<RSVP>>();
  const [submitted, setSubmitted] = React.useState(false);

  const [invite, setInvite] = React.useState<Invite>();
  const [attemptMade, setAttemptMade] = React.useState<boolean>(false);

  const { mutate, isPending } = useMutation({
    mutationFn: async (rsvp: RSVP) => {
      const result = await fetch('https://api.bryan-podge-2024.com/api/rsvp', {
        body: JSON.stringify(rsvp),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      });

      return result.status;
    }
  });

  const onInvite = (invite: Invite | undefined) => {
    if (invite) {
      setInvite(invite);
    }

    setAttemptMade(true);
  };

  const onSubmit = async (values: RSVP) => {
    await formikRef.current?.validateForm();

    if (formikRef.current?.isValid) {
      const body = { id: values.id, guest1: values.guest1, guest2: values.guest2 };

      mutate(body, {
        onSuccess: () => {
          setSubmitted(true);
        }
      });
    }
  };

  return (
    <Page header='RSVP' gallery={[]}>
      {submitted ? (
        <>
          <Alert icon={<CheckIcon fontSize='inherit' />} severity='success'>
            RSVP submitted. Thank you!
          </Alert>
        </>
      ) : (
        <Formik<RSVP>
          innerRef={formikRef}
          initialValues={initialValues as any}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              <RSVPForm onInviteRetrieved={onInvite}></RSVPForm>
              <div className='flex justify-between'>
                {invite || formik.values.plusOne || !attemptMade ? (
                  <div></div>
                ) : (
                  <div>
                    <button
                      className='btn-invert border mt-4 border-gray-500'
                      type='button'
                      onClick={() => {
                        formik.setFieldValue('plusOne', true);
                      }}
                    >
                      <AddIcon /> add Guest
                    </button>
                  </div>
                )}
                <button className='btn mt-4' type='submit' disabled={formik.isSubmitting || isPending}>
                  RSVP
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
}
