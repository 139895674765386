import { Header } from './Header/Header';
import { Menu } from './Menu/Menu';

export interface PageProps extends React.PropsWithChildren {
  header: string;
  gallery?: { name: string; caption?: string }[];
}

export function Page({ header, gallery, children }: PageProps) {
  return (
    <div className='bg-line-pattern flex flex-col justify-stretch items-stretch min-h-full'>
      <div className='flex flex-col justify-stretch items-center w-full min-h-full grow'>
        <div className='flex flex-col bg-white bg-no-repeat shadow-custom max-w-[1000px] justify-stretch items-stretch min-h-full bg-flowers grow w-full'>
          <div>
            <Menu></Menu>
          </div>
          <div className='flex flex-col justify-stretch items-stretch grow m-8 lg:m-10 bg-faded-white '>
            <Header>{header}</Header>
            <div className='flex flex-col justify-stretch items-center w-full text-center text-black font-bold font-gel'>{children}</div>
            <div className='mt-6 h-auto w-full flex flex-col gap-5 items-stretch first-line:text-sm text-black font-bold font-gel'>
              {gallery?.map(({ name, caption }, index) => (
                <div key={index} className='flex flex-col items-center'>
                  <img alt={caption} className='max-w-[800px] w-full' src={'/images/' + name} />
                  {caption ? <div className='h-3 text-xs text-center'>{caption}</div> : <div className='h-3 text-xs'> </div>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
