import { Page } from '../page';

export function Day() {
  return (
    <Page
      header='Plan of Action'
      gallery={[
        { name: 'pb2.jpg', caption: '' },
        { name: 'house-2.jpg', caption: 'Ashley Park' },
        { name: 'dining.jpg', caption: 'Pavilion - Ashley Park' },
        { name: 'ceremony-room.jpg', caption: 'Ceremony Room - Ashley Park' }
      ]}
    >
      <div className='flex flex-col items-center justify-center p-6'>
        <p className='my-3'>
          We are getting married and would like you to come celebrate the day with us! Our wedding will take place on Sunday August 4th '24 in the
          beautiful setting of Ashley Park House near Nenagh, Co. Tipperary.
        </p>
        <h3 className='mt-10 text-2xl'>Schedule</h3>
        <hr className='w-64 my-3' />
        <div className='text-sm text-center my-3'>
          <h3 className='text-lg'>2:00pm - Guest Check-in</h3>
          <div>Guests staying in Ashley park can check in before the ceremony</div>
        </div>
        <hr className='w-20' />
        <div className='text-center my-3'>
          <h3 className='text-lg'>3:00pm - Ceremony</h3>
          <div>The ceremony will take place in the lake side ceremony room</div>
        </div>
        <hr className='w-20 my-3' />
        <div className='text-center my-3'>
          <h3 className='text-lg'>3:30pm - Drinks Reception</h3>
          <div>Drinks reception will take place on the lawn/main house</div>
        </div>
        <hr className='w-20 my-3' />
        <div className='text-center my-3'>
          <h3 className='text-lg'>5:30pm - Call for Dinner</h3>
          <div>Time to find your seats in the pavilion</div>
        </div>
        <hr className='w-20 my-3' />
        <div className='text-center my-3'>
          <h3 className='text-lg'>6:00pm - Dinner is Served</h3>
          <div>We hope you are hungry</div>
        </div>
        <hr className='w-20 my-3' />
        <div className='text-center my-3'>
          <h3 className='text-lg'>9:30pm - Party!</h3>
          <div>Let's get dancing</div>
        </div>
      </div>
    </Page>
  );
}
