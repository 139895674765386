import { Navigate, Route, Routes } from 'react-router-dom';

import { Accommodation } from './Accom/Accom';
import { Day } from './Day/Day';
import { DayTwo } from './DayTwo/DayTwo';
import { Home } from './Home/Home';
import { RSVPPage } from './RSVP/RSVP';
import { Venue } from './Venue/Venue';

import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/plan-of-action' element={<Day />}></Route>
      <Route path='/venue' element={<Venue />}></Route>
      <Route path='/accommodation' element={<Accommodation />}></Route>
      <Route path='/day-two' element={<DayTwo />}></Route>
      <Route path='/rsvp' element={<RSVPPage />}></Route>
      <Route path='*' element={<Navigate to='/' />}></Route>
    </Routes>
  );
}

export default App;
