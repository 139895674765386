import { useField } from 'formik';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

import React from 'react';
import { Invite } from '../models/models';
import { Guest } from './Guest';

export interface RSVPFormProps {
  onInviteRetrieved?: (invite: Invite | undefined) => void;
}

export function RSVPForm({ onInviteRetrieved }: RSVPFormProps) {
  const [{ value: g1First }, , { setValue: setG1First }] = useField('guest1.firstName');
  const [{ value: g1Last }, , { setValue: setG1Last }] = useField('guest1.lastName');
  const [{ value: g2First }, , { setValue: setG2First }] = useField('guest2.firstName');
  const [{ value: g2Last }, , { setValue: setG2Last }] = useField('guest2.lastName');
  const [, , { setValue: setG2Attending }] = useField('guest2.attending');
  const [{ value: plus }, , { setValue: setPlus }] = useField('plusOne');
  const [, , { setValue: setId }] = useField('id');

  const [invite, setInvite] = React.useState<Invite>();

  const search = async ({ first, last }: { first: string; last: string }) => {
    let result: Invite | undefined;
    if (!invite) {
      const response = await fetch(`https://api.bryan-podge-2024.com/api/rsvp?fn=${first?.trim()}&ln=${last?.trim()}`);

      if (response.ok) {
        try {
          result = await response.json();
        } catch {
          console.log('bad response');
        }
      }

      if (result && result.id) {
        setInvite(result);

        setId(result.id);

        setG1First(result.guest1.firstName);
        setG1Last(result.guest1.lastName);

        if (result.guest2) {
          setPlus(true);
          setG2First(result.guest2.firstName);
          setG2Last(result.guest2.lastName);
        }
      }

      onInviteRetrieved?.(result);
    }
  };

  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls='panel1-content' id='panel1-header' expandIcon={<ArrowDropDownIcon />}>
          {`${invite?.guest1.firstName || g1First || 'Guest 1'} ${invite?.guest1.lastName || g1Last || ''}`}
        </AccordionSummary>
        <AccordionDetails>
          <Guest name='guest1' onGuestEntered={search}></Guest>
        </AccordionDetails>
      </Accordion>
      {invite?.guest2 || plus ? (
        <Accordion defaultExpanded={false}>
          <AccordionSummary aria-controls='panel1-content' id='panel1-header' expandIcon={<ArrowDropDownIcon />}>
            <div className='flex justify-between pr-4 w-full'>
              {`${invite?.guest2.firstName || g2First || 'Guest 2'} ${invite?.guest2.lastName || g2Last || ''}`}
              {invite?.guest2 ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    setPlus(false);
                    setG2Attending('false');
                  }}
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Guest name='guest2'></Guest>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
}
