import { Field, useField } from 'formik';
import { RadioGroup } from '../inputs/radio-input';
import { TextInput } from '../inputs/text-input';
import { Dessert, Mains, SoupSorbet, Starter } from '../models/models';

export interface GuestProps {
  name: string;
  onGuestEntered?: (name: { first: string; last: string }) => void;
}

export function Guest({ name, onGuestEntered }: GuestProps) {
  const [{ value: first }] = useField(name + '.firstName');
  const [{ value: last }] = useField(name + '.lastName');
  const [{ value: attending }] = useField(name + '.attending');
  const [, { error, touched }] = useField(name + '.dietaryRequirements');

  const handleBlur = () => {
    if (first && last) {
      onGuestEntered?.({ first, last });
    }
  };

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-row gap-4 flex-wrap justify-between'>
        <TextInput
          label='First name'
          onBlur={handleBlur}
          name={name + '.firstName'}
          className='border border-gray-700 px-2 rounded-sm'
          placeholder='First Name'
        />
        <TextInput
          label='Last name'
          onBlur={handleBlur}
          name={name + '.lastName'}
          className='border border-gray-700 px-2 rounded-sm'
          placeholder='last Name'
        />
      </div>
      <div className='flex flex-col'>
        <RadioGroup
          name={name + '.attending'}
          options={[
            { key: 'Accept with pleasure', value: 'true' },
            { key: 'Decline with regret', value: 'false' }
          ]}
        />
      </div>
      {attending === 'true' ? (
        <>
          <div className='flex flex-row flex-wrap justify-between'>
            <TextInput
              label='Song suggestion'
              name={name + '.songRequest'}
              className='border border-gray-700 px-2 rounded-sm'
              placeholder='Song suggestion'
            />
          </div>
          <div className='flex flex-row flex-wrap justify-between'>
            <div className='w-full flex flex-col gap-2'>
              <label htmlFor={name + '.dietaryRequirements'} className='text-left'>
                Dietary Requirements:
              </label>
              <Field
                as='textarea'
                rows={3}
                id={name + '.dietaryRequirements'}
                name={name + '.dietaryRequirements'}
                placeholder='Dietary Requirements'
                className={'w-full border border-gray-700 px-2 rounded-sm ' + (error && touched ? 'border-red-500' : '')}
              />
              <div className='h-6 text-red-500 text-right'>{error}</div>
            </div>
          </div>
          <div>
            <h4 className='text-2xl mb-2'>Starter</h4>
            <RadioGroup
              name={name + '.starter'}
              options={[
                { key: 'Chicken & Mushroom Vol-au-vent', value: Starter.ChickenAndMushroom },
                { key: 'Warm Hoisin Duck Breast Salad, with garden leaves', value: Starter.Duck }
              ]}
            />
          </div>
          <div>
            <h4 className='text-2xl mb-2'>Soup or Sorbet</h4>
            <RadioGroup
              name={name + '.soupSorbet'}
              options={[
                { key: 'Celeriac & Chorizo Soup', value: SoupSorbet.Soup },
                { key: 'Mango & Passionfruit Sorbet', value: SoupSorbet.Sorbet }
              ]}
            />
          </div>
          <div>
            <h4 className='text-2xl mb-2'>Main Course</h4>
            <RadioGroup
              name={name + '.mains'}
              options={[
                { key: 'Beef Wellington served with roast vegetables, a gratin of potatoes', value: Mains.Wellington },
                { key: 'Pan Fried Sea Bass with garlic and herb cream sauce', value: Mains.Fish },
                { key: 'Roast Vegetable & Chickpea Masala in a coconut cream sauce', value: Mains.Veg }
              ]}
            />
          </div>
          <div>
            <h4 className='text-2xl mb-2'>Dessert</h4>
            <RadioGroup
              name={name + '.dessert'}
              options={[
                { key: 'Warm Apple and Rhubarb Crumble with homemade custard', value: Dessert.Crumble },
                { key: 'Chocolate Cake with Cointreau Glaze with ice-cream & red berries', value: Dessert.ChocolateCake }
              ]}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
