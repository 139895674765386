import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const links = [
  { path: '/', name: 'Home' },
  { path: '/plan-of-action', name: 'Plan of Action' },
  { path: '/venue', name: 'Venue' },
  { path: '/accommodation', name: 'Accommodation' },
  { path: '/day-two', name: 'Day two' },
  { path: '/rsvp', name: 'RSVP' }
];

export function Menu() {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <nav className='w-full'>
      {/* <div className='hidden lg:flex p-3  gap-2 items-center justify-center'>
        {links.map(({ path, name }) => (
          <Link key={path} to={path}>
            <button
              className={cx('w-36 p-2 rounded-md font-medium', {
                'bg-white border-black border text-black': pathname === path,
                'bg-black text-white': pathname !== path
              })}
            >
              {name}
            </button>
          </Link>
        ))}
      </div> */}

      <div className='flex justify-center relative w-full'>
        <button
          className={cx('flex justify-between items-center h-16 w-full p-5 text-2xl font-medium', {
            'bg-black text-white shadow-2xl': !isOpen,
            'bg-white border-black text-black border-b-4': isOpen
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            <FontAwesomeIcon icon={faBars} />
            <span className='hidden lg:inline-block font-bold text-3xl ml-4'>Menu</span>
          </span>
          <div className='text-2xl text-bold font-old'>
            4<sup>th</sup> Aug '24
          </div>
        </button>
        <div
          className={cx('w-full flex top-full flex-col items-center absolute z-top ease-in-out duration-300 transition-height overflow-hidden', {
            'h-0': !isOpen,
            'h-[450px]': isOpen
          })}
        >
          {links.map(({ path, name }, index) => (
            <Link key={index} className='w-full' to={path} onClick={() => setIsOpen(false)}>
              <button
                className={cx('lg:w-1/3 text-left shadow-black shadow-lg w-full h-16 text-2xl p-3 font-medium border-y border-collapse', {
                  'bg-white border-black border text-black': pathname === path,
                  'bg-black text-white': pathname !== path
                })}
              >
                {name}
              </button>
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}
