import { Field, FieldAttributes, useField } from 'formik';

export interface RadioGroupProps extends FieldAttributes<any> {
  options: { key: string; value: string | boolean | number }[];
}
export function RadioGroup({ options, name, ...props }: RadioGroupProps) {
  const [, { error }] = useField(name);
  return (
    <div className='text-left flex w-full flex-col gap-2 justify-start items-start'>
      {options.map(({ key, value }) => (
        <label key={value?.toString()}>
          <Field {...props} type='radio' name={name} value={value} className='mr-2' />
          {key}
        </label>
      ))}
      <div className='h-4 text-red-500'>{error}</div>
    </div>
  );
}
