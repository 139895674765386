import { Page } from '../page';

export function Venue() {
  return (
    <Page
      header='Venue'
      gallery={[
        { name: 'pb3.jpeg' },
        { name: 'night.jpg', caption: 'Ashley Park - Main house' },
        { name: 'house.jpg', caption: 'Ashley Park' },
        { name: 'dining.jpg', caption: 'Pavilion - Ashley Park' },
        { name: 'ceremony.jpg', caption: 'Ceremony Room - Ashley Park' }
      ]}
    >
      <div className='text-center my-2'>
        <h3 className='text-2xl'>Ashley Park House</h3>
        <div>Ardcroney, Tipperary</div>
        <div className='text-sm'>E45 RW32</div>
      </div>
      <hr className='w-40 my-2' />
      <div className='text-center my-2 p-6'>
        <p className='max-w-[800px]'>
          Ashley park house is a beautifully restored home set into 76 acres of woodland, where beautiful celebrations come to life and lasting
          memories are made. This venue boasts amazing lake views and breathtaking scenery, which is home to many wild birds and animals.
          <br />
          <br />
          It is located just 10 minutes north of Nenagh, Co. Tipperary
        </p>
        <a href='https://ashleypark.com/' rel='noreferrer' target='_blank'>
          <button className='mt-16 w-36 p-2 rounded-md font-medium bg-black text-white'>Find out more</button>
        </a>
      </div>
      <div className='rounded-r-xl h-auto w-full flex flex-col items-center'>
        <iframe
          width='100%'
          height='500'
          title='Google Maps'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2405.2664236693436!2d-8.18296462305301!3d52.92563220611867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485c9140464bc0ab%3A0x91a759a1c9217be1!2sAshley%20Park%20House!5e0!3m2!1sen!2sie!4v1707341367052!5m2!1sen!2sie'
          loading='lazy'
        ></iframe>
      </div>
    </Page>
  );
}
