import { Menu } from '../Menu/Menu';

export function Home() {
  return (
    <div className='bg-line-pattern flex w-full h-full grow justify-center items-center min-h-full'>
      <div className='max-w-2xl bg-no-repeat grow flex flex-col justify-center h-full items-center '>
        <div className='w-full flex'>
          <Menu></Menu>
        </div>
        <div className='font-old main-img flex flex-col justify-end grow w-full px-2 py-8 text-center font-bold text-white'>
          <h3 className='text-6xl font-old'>Padraig & Bryan</h3>
          <br />
          <br />
          <h3 className='text-4xl'>Ashley Park House</h3>
          <br />
          <h3 className='text-3xl'>Nenagh, Tipperary</h3>
          <br />
          <h3 className='text-3xl'>
            Sunday 4<sup>th</sup> August '24
          </h3>
        </div>
      </div>
    </div>
  );
}
